import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import PageHeader from "../components/common/PageHeader"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Product from "../components/shop-all/product"

import { Body, BodyMedium, Header2 } from "../components/typography"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"
import typography from "../styles/typography"

const Wrapper = styled.div`
  display: flex;
  color: ${colors.creamLight};
  flex-direction: column;

  padding: 40px 24px;

  max-width: 1440px;
  margin: 0 auto;

  ${Header2} {
    margin-bottom: 24px;
  }

  ${BodyMedium} {
    margin-bottom: 16px;
  }

  position: relative;

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
    padding: 40px;

    > ${Header2} {
      flex: 0 0 45%;
    }

    > div {
      flex: 0 0 55%;
    }
  }
`

const TextButton = styled.button`
  ${typography.metaLarge.desktop}

  appearance: none;
  background: none;
  border: 0;

  padding: 0;
  margin-top: 24px;

  text-transform: uppercase;
  color: ${colors.orangeDark};

  cursor: pointer;
`

export const query = graphql`
  query GetProducts {
    allContentfulProduct {
      nodes {
        id
        slug
        productDescription {
          raw
        }
        productTitle
        productImage {
          gatsbyImage(width: 560, height: 560, placeholder: BLURRED)
        }
      }
    }
  }
`

export function Head() {
  return <Seo title={"Shop All"} />
}

export default function Shop({ data }) {
  const { allContentfulProduct: products } = data

  return (
    <Layout>
      <PageHeader
        header="Shop All"
        subheader="At Drømme, we’re inspiring new cocktail experiences that aren’t
            defined by alcohol, for people who aren’t defined by alcohol."
        body={
          <>
            Whether you’re thoughtfully abstaining or pacing yourself through
            the night, Drømme zero-proof is an option to stay clear minded and
            present in your moment.
            <br />
            <br /> Expand your cocktail spectrum with our bespoke, zero-proof
            blends of botanicals and adaptogens. They’re nootropic drinks for
            everyone.
          </>
        }
        cta="View Products"
        onCtaClick={() => window.scrollBy(0, 265)}
      />
      {products?.nodes?.map((product: any, i) => (
        <Product product={product} flipped={!(i % 2)} />
      ))}
    </Layout>
  )
}
